import React from "react";

import { BsFacebook, BsTwitterX, BsInstagram } from "react-icons/bs";
function SocialMediaIcons() {
  const iconSize = 32; // Common size for icons
  const iconColor = "$white"; // Main color of your theme

  return (
    <div>
      <a href="https://example.com">
        <BsFacebook
          color={iconColor}
          size={iconSize}
          style={{ marginRight: ".625rem" }}
        />
      </a>

      <a href="https://example.com">
        <BsTwitterX
          color={iconColor}
          size={iconSize}
          style={{ marginRight: ".625rem" }}
        />
      </a>

      <a href="https://example.com">
        <BsInstagram color={iconColor} size={iconSize} />
      </a>
    </div>
  );
}

export default SocialMediaIcons;
