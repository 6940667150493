import React from "react";
import "./LoadingSpinner.scss";
const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-container__spinner"></div>
      {/* This is where you can change the text inside the spinner */}
      <div className="spinner-container__no-animation">Koshegio</div>
      <div className="spinner-container__no-animation-backgroud"></div>
      <div className="spinner-container__no-animation-backgroud2"></div>
    </div>
  );
};

export default LoadingSpinner;
